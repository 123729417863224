export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "cs": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nápověda"])},
        "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verze"])}
      },
      "de": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe"])},
        "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])}
      },
      "es": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayuda"])},
        "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión"])}
      },
      "fr": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide"])},
        "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])}
      },
      "id": {
        "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versi"])}
      },
      "it": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiuto"])},
        "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versione"])}
      },
      "ja": {
        "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バージョン"])}
      },
      "sw": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["msaada"])},
        "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toleo"])}
      }
    }
  })
}
